import React, { FC,useState,useEffect } from "react";
import facebookSvg from "images/Facebook.svg";

import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link ,useHistory} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
  signInWithFacebook
} from "../../firebase/firebase";
import axios from 'axios'
import Swal from 'sweetalert2'  


export interface PageSignUpProps {
  className?: string;
}



const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useHistory()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname,setLname] = useState("")
  const [phone,setPhone] = useState("")
  const [confirm,setConfirm] = useState("") 
  const [user, loading, error] = useAuthState(auth);

  const postUser = () => {
    const obj = {
      'email' : email,
      'fname' : fname,
      'lname' : lname,
      'phone' : phone
    }

    registerWithEmailAndPassword(fname,lname,phone,email,password)
    .then(res => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your Account Has Been Created',
        showConfirmButton: false,
        timer: 1500
      })
    })
    .catch(error => {
      console.log(error.message)
    })
  }

  const register = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(password !== confirm){
        alert("Passwords do not match")
    }
    else{
      postUser()
    }
  };

  useEffect(()=>{
    if (loading) return;
    if(error){
      console.log(error)
    }
    if(user){
        navigate.push('/')
    }
},[user,loading])


  const inputEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setEmail(enteredName);
  };

  const inputfNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setFname(enteredName);
  };

  const inputlNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setLname(enteredName);
  };

  const inputPhoneHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setPhone (enteredName);
  };

  const inputPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setPassword(enteredName);
  };
  
  const inputConfirmHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setConfirm(enteredName);
  };

  const onGoogle = () => {
    signInWithGoogle()
}

const onFacebook = () => {
   signInWithFacebook()
}



  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Fixzera</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
        <div className="grid gap-3">
              <a
              onClick={onFacebook}
                href="##"
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={facebookSvg}
                  alt='Facebook'
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  Continue With Facebook
                </h3>
              </a>

              <a
              onClick={onGoogle}
                href="##"
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={googleSvg}
                  alt='Google'
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  Continue With Google
                </h3>
              </a>
      
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={register}>
          <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                placeholder="eg Kofi"
                className="mt-1"
                value={fname}
                onChange={inputfNameHandler}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                placeholder="eg Owusu"
                className="mt-1"
                value={lname}
                onChange={inputlNameHandler}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onChange={inputEmailHandler}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone Number
              </span>
              <Input
                type="phone"
                placeholder="eg 02000000000"
                className="mt-1"
                value={phone}
                onChange={inputPhoneHandler}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                type="password"
 
                className="mt-1"
                value={password}
                onChange={inputPasswordHandler}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password" className="mt-1" value={confirm} onChange={inputConfirmHandler}/>
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
