import React, { FC,useState,useEffect } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import GoogleMapReact from "google-map-react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import Label from "components/Label/Label";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../../firebase/firebase";
import { query, collection, getDocs, where } from "firebase/firestore"


export interface PageAddListing1Props {}

const PageAddListing1: FC<PageAddListing1Props> = () => {

  const navigate = useHistory()
  const [user, loading, error] = useAuthState(auth);
  const [orderBy,setOrderBy] = useState('')
  const [data,setData] = useState({
    device:'',
    brand:'',
    model:'',
    fault:'',
    town:'',
    landmark:'',
    otherBrand:'',
    comments:''
  })

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [status, setStatus] = useState('');


  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "Users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setOrderBy(data.email)
    } catch (err) {
      console.error(err);
      
    }
  };

  const getLocation = () => {

      if (!navigator.geolocation) {
        setStatus('Geolocation is not supported by your browser');
      } else {
        setStatus('Locating...');
        navigator.geolocation.getCurrentPosition((position) => {
          setStatus('');
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          console.log(position)
          alert('Location Captured')
        }, () => {
          setStatus('Unable to retrieve your location');
        });
      }
  }

  const handleData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name,value} = e.target
    setData({...data,[name]:value})
  }

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setData({...data,device:value});
  };

  const selectBrandChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setData({...data,brand:value});
  };

  const selectFaultChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setData({...data,fault:value});
  };

  useEffect(()=>{
    fetchUserName()

  },[user])

  const postData = () => {

    const obj = {
      "orderBy":orderBy,
      "email":orderBy,
      "dropOff":"",
      "comments":data.comments,
      "item":data.device,
      "model":data.model,
      "problem":data.fault,
      "gps":{lat,lng},
      "brand":data.brand,
      "town":data.town,
      "landmark":data.landmark,
      "otherBrand":data.otherBrand,
      "startDate":'',
      "endDate":'',
      "paid":false
    }

    axios.post("https://us-central1-fixzera-97d22.cloudfunctions.net/app/addRequest",obj)
    .then(response=>{
      
      Swal.fire(
        'Great!!',
        'Your request has been submitted',
        'success'
      )
      navigate.push({
        pathname:"/checkout",
        state:{
          id:response.data.id
        }
      })
    })
    .catch(error => {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: '<a href="">Please try again</a>'
      })
    })
  }

    useEffect(()=> {
        if(!user){
          navigate.push('/')
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please Login to place an Order',
            
          })
        }
    },[user])




  return (
    <CommonLayout
      index=""
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
    >
      <>
   
        <h2 className="text-2xl font-semibold">Device Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Choose device type"
            desc="Device type: laptops, desktops, phones, etc..."
          >
            <Select name='device' onChange={selectChange}>
              <option value="Laptop">Laptop</option>
              <option value="Smart Phone">Smart Phone</option>
              <option value="Television Set">Television Set</option>
              <option value="Tablet">Tablet</option>
              <option value="Desktop">Desktop PC</option>
            </Select>
          </FormItem>
          <FormItem
            label="Brand"
            desc="Device Brand: samsung,apple, panasonic, etc..."
          >
            <Select name="brand" onChange={selectBrandChange}>
              <option value="Samsung">Samsung</option>
              <option value="Apple">Apple</option>
              <option value="Lenovo">Lenovo</option>
              <option value="HP">HP</option>
              <option value="Dell">Dell</option>
              <option value="Acer">Acer</option>
              <option value="Microsoft">Microsoft</option>
              <option value="Google">Google</option>
              <option value="Asus">Asus</option>
              <option value="MSI">MSI</option>
              <option value="Xioami">Xioami</option>
              <option value="Inifinix">Infinix</option>
              <option value="Huawei">Huawei</option>
              <option value="Panasonic">Panasonic</option>
              <option value="Sony">Sony</option>
              <option value="LG">LG</option>
              <option value="other">Other</option>
            </Select>
          </FormItem>
          <FormItem label="If other, please specify">
            <Input placeholder="Name of brand" value={data.otherBrand} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{setData({...data,otherBrand:e.target.value})}}/>
          </FormItem>
          <FormItem label="Model" desc="Device Model">
            <Input placeholder="Device make and model. Skip if not available!" value={data.model} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{setData({...data,model:e.target.value})} }/>
          </FormItem>
          <FormItem
            label="Fault"
            desc="Whats wrong with your device, select from list of possible issues"
          >
            <Select onChange={selectFaultChange}>
              <option value="Faulty Screen">Faulty Screen</option>
              <option value="Decreased Performance">Decreased Performance</option>
              <option value="Power Issues">Power Issues</option>
              <option value="Weird Noise">Weird Noise</option>
              <option value="Keyboard">Over Heating</option>
              <option value="USB">USB</option>
            </Select>
          </FormItem>
          <FormItem label="Further Comments" desc="Brief description of the issue">
            <Textarea value={data.comments} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=>{setData({...data,comments:e.target.value})}}  placeholder=" 
             please input here" />
          </FormItem>
        </div>

        <h2 className="text-2xl font-semibold">Pickup Dropoff Address</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {!lat?(<ButtonSecondary onClick={getLocation}>
            <LocationMarkerIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span className="ml-3">Use current location</span>
          </ButtonSecondary>):(
                <ButtonSecondary onClick={getLocation}>
            <LocationMarkerIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span className="ml-3">Coordinates Captured</span>
          </ButtonSecondary>
          )}
          

      
          {/* ITEM */}
          <FormItem label="Town/Suburb">
            <Input value={data.town} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{setData({...data,town:e.target.value})}} />
          </FormItem>
          <FormItem label="Landmark">
            <Input placeholder="..." value={data.landmark} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{setData({...data,landmark:e.target.value})}} />
          </FormItem>
          

          <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
            
            </span>
            {/*<div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
                    }}
                    defaultZoom={30}
                    defaultCenter={{
                      lat: 5.8280365721842315,
                      lng: -0.2199538393967037,
                    }}
                  >
                    <LocationMarker lat={lat} lng={lng} />
                  </GoogleMapReact>
                </div>
              </div>
                  </div>*/}
            <br></br>
            <ButtonPrimary onClick={postData}>Submit Request</ButtonPrimary>
          </div> 
          
        </div>

      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
