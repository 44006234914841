import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "./LangDropdown";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import DropdownTravelers from "./DropdownTravelers";
import { Link } from "react-router-dom";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../../firebase/firebase";
import { query, collection, getDocs, where } from "firebase/firestore"

export interface MainNav2Props {
  isTop: boolean;
}

const MainNav2: FC<MainNav2Props> = ({ isTop }) => {

  const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");

    const fetchUserName = async () => {
        try {
          const q = query(collection(db, "Users"), where("uid", "==", user?.uid));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
    
          setName(data.fname);
        } catch (err) {
          console.error(err);
          
        }
      };
    
      useEffect(()=>{
        fetchUserName()
 
      },[user])
  return (
    <div
      className={`nc-MainNav1 nc-MainNav2 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          <Navigation />
          <div className="hidden sm:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
 
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">


            <div></div>
            
          
            <br></br>
            <div>{name}</div>
            <br></br>
            <AvatarDropdown />
            {user?<NotifyDropdown />:<a>.</a>}
          </div>
          <div className="flex items-center space-x-4 xl:hidden">
            {user?<NotifyDropdown />:<a>.</a>}
            <AvatarDropdown />
            <MenuBar />
          </div>
          <div className="hidden items-center xl:flex space-x-4">

            <div className="px-1" />
            <ButtonPrimary href="/make-an-order">Place An Order</ButtonPrimary>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
