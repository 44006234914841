import React, { FC } from "react";
import rightImgPng from "images/asda.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >

        <h2 className="font-semibold text-4xl mt-5">Get Back To Work! </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="Device Repairs" />
            <span className="block text-xl font-semibold">
              Cost-effective repairs
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Get high quality yet within your-budget repair services
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Dispatch and Delivery " />
            <span className="block text-xl font-semibold">
              Hi-speed delivery services
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
             Enjoy the convenience of our express delivery services. You do not have to move an inch for repairs.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Secure" />
            <span className="block text-xl font-semibold">
              Secure and simple
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Your devices are safe with us. Track all the updates on device repairs; from reception to completion of repairs.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
