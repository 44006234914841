import Label from "components/Label/Label";
import React, { FC,useState,useEffect } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../../firebase/firebase";
import { query, collection, getDocs, where,doc,updateDoc } from "firebase/firestore"
import Swal from "sweetalert2";



export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const [user, loading, error] = useAuthState(auth);
  const [fname,setFname] = useState('')
  const [lname,setLname] = useState('')
  const [email,setEmail] = useState('')
  const [phone,setPhone] = useState('')
  const [tempfname,setTempFname] = useState('')
  const [tempLname,setTempLname] = useState('')
  const [tempEmail,setTempEmail] = useState('')
  const [tempPhone,setTempPhone] = useState('')

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "Users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setEmail(data.email)
      setTempEmail(data.email)
      setFname(data.fname)
      setTempFname(data.fname)
      setLname(data.lname)
      setTempLname(data.lname)
      if(data.phone){
        setPhone(data.phone)
        setTempPhone(data.phone)
      }else{
        setPhone("Please provide your phone number")
      }
    } catch (err) {
      console.error(err);
      
    }
  };

  const makeFname = async() => {
    const changeRef = doc(db, "Users", email);
    await updateDoc(changeRef, {
      fname:fname,
    })
 
  } 

  const makeLname = async() => {
    const changeRef = doc(db, "Users", email);
    await updateDoc(changeRef, {
      lname:lname,
    })
 
  } 
  const makePhone = async() => {
    const changeRef = doc(db, "Users", email);
    await updateDoc(changeRef, {
      phone:phone,
    })

  } 
  const makeEmail = async() => {
    const changeRef = doc(db, "Users", email);
    await updateDoc(changeRef, {
      email:email,
    })
 
  } 

  const makeUpdates = () => {
    if(fname !== tempfname){
      makeFname()
    }
    if(lname !== tempLname){
      makeLname()
    }
    if(phone !== tempPhone){
      makePhone()
    }
    if(email !== tempEmail){
      makeEmail()
    }

    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Changes Successful',
      showConfirmButton: false,
      timer: 2500
    })
  } 

  useEffect(()=>{
    fetchUserName()

  },[user])

  const inputEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setEmail(enteredName);
  };

  const inputfNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setFname(enteredName);
  };

  const inputlNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setLname(enteredName);
  };

  const inputPhoneHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setPhone (enteredName);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Fixzera</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>First Name</Label>
                <Input className="mt-1.5" defaultValue={fname} value={fname} onChange={inputfNameHandler} />
              </div>
              <div>
                <Label>Second Name</Label>
                <Input className="mt-1.5" defaultValue={lname} value={lname} onChange={inputlNameHandler} />
              </div>
              <div>
                <Label>Email**Cannot be Changed</Label>
                <Input className="mt-1.5" defaultValue={email} value={email} onChange={inputEmailHandler} />
              </div>
              <div>
                <Label>Phone Number</Label>
                <Input className="mt-1.5" defaultValue={phone} value={phone} onChange={inputPhoneHandler} />
              </div>
              
              <div className="pt-2">
                <ButtonPrimary onClick={makeUpdates}>Update info</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
