
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { Fragment, useState,useEffect } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../../firebase/firebase";
import { query, collection, getDocs, where,doc,updateDoc } from "firebase/firestore"
import SectionGridFilterCard from "./SectionGridFilterCard.tsx";
import axios from 'axios'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


const AccountSavelists = () => {



  return (
    <div>
      <CommonLayout>
      <div className="container relative">
    
        <SectionGridFilterCard className="pb-24 lg:pb-32" />
      </div>
      </CommonLayout>
    </div>
  );
};

export default AccountSavelists;
