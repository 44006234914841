import React, { FC, useState , useEffect } from "react";

import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps } from "./FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";

export interface SectionGridFilterCardProps {
  className?: string;

}



const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {

  const [data,setData] = useState([])
  const getData = () =>{
    axios.get('https://us-central1-fixzera-97d22.cloudfunctions.net/app/getUserOrders?userEmail=mike.knight.mk23@gmail.com')
    .then(res => {
      setData(res.data)
    })
  }

  useEffect(()=> {
    getData()
  },[data])

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {data.map((item, index) => (
          <FlightCard key={index} data={item} />
        ))}

        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary loading>Show more</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
