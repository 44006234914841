import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,doc,
  setDoc
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBz1s1BpaaNeQhhf2jT6Z-RO1dFAkiqiUQ",
  authDomain: "fixzera-97d22.firebaseapp.com",
  projectId: "fixzera-97d22",
  storageBucket: "fixzera-97d22.appspot.com",
  messagingSenderId: "297083839636",
  appId: "1:297083839636:web:c028a99609d43b8a424081",
  measurementId: "G-F2W6QN4FW6"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider()

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const fullName = user.displayName;
    const splitOnSpace = fullName.split(' ')    
    const first = splitOnSpace[0];
    const last = splitOnSpace[1]
    const docRef = await setDoc(doc(db, "Users", user.email ), {
      uid: user.uid,
      fname:first,
      lname:last,
      phone:'',
      authProvider: "Email",
      email:user.email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithFacebook = async () => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;
    const fullName = user.displayName;
    const splitOnSpace = fullName.split(' ')    
    const first = splitOnSpace[0];
    const last = splitOnSpace[1]
    const docRef = await setDoc(doc(db, "Users", user.email ), {
      uid: user.uid,
      fname:first,
      lname:last,
      phone:'',
      authProvider: "Email",
      email:user.email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);

  }
};

const registerWithEmailAndPassword = async (fname,lname,phone, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const docRef = await setDoc(doc(db, "Users", email ), {
      uid: user.uid,
      fname,
      lname,
      phone,
      authProvider: "Email",
      email,
    });
    console.log(fname,lname,phone,email)
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  signInWithFacebook,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};